<template>  
    <v-card elevation="2" tile width="100%" class="pa-0 pa-4">
        <div class="skeleton-list-item w-full" v-if="donateSearchLoading">
            <v-skeleton-loader  :loading="true" type="list-item" v-bind="{class: 'mb-4'}"></v-skeleton-loader>
            <v-skeleton-loader  :loading="true" type="list-item" v-bind="{class: 'mb-4'}"></v-skeleton-loader>
            <v-skeleton-loader  :loading="true" type="list-item" v-bind="{class: 'mb-4'}"></v-skeleton-loader>
        </div>
        <vue-perfect-scrollbar v-if="!donateSearchLoading" style="height: calc(100vh - 200px)">
            <template v-for="(item, i) in donateLists">
                <div class="donation-item text-h6 primary--text font-weight-bold pa-2 mb-4" :key="i">
                        <div class="float-right">
                            <v-icon v-if="item.source === 'Call Centre'" color="secondary" class="mr-2">mdi-phone</v-icon>
                            <v-icon v-if="item.source === 'Web Donation Form'" color="secondary" class="mr-2">mdi-application-outline</v-icon>
                            <v-icon v-if="item.recurring" color="info" class="mr-2">mdi-autorenew</v-icon>
                            <v-icon v-if="item.new_donor === 1" color="info" class="mr-2">mdi-new-box</v-icon>
                        </div>
                        <div class="text-break">
                            <span v-if="item.do_not_read === 0">{{ item.donor_first_name }},</span>
                            <span class="text-uppercase ml-2">{{ item.donor_suburb || ''}}</span>
                            <span class="ml-2" v-if="item.visible_price === 1">${{item.amount | amountTrim}}</span>
                        </div>
                </div>
            </template>
        </vue-perfect-scrollbar>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: (vm) => ({
        donateLists: []
    }),
    computed: {
        ...mapGetters(['donateSearchLoading'])
    },
    filters: {
        amountTrim: function(amount) {
            const decAmt = amount && amount.includes('.') ? amount.split('.') : null;
            if (decAmt && parseInt(decAmt[1]) > 0) {
                return amount;
            }
            return parseInt(amount);
        },
    },
    created() {
        this.getDonateLists();
    },
    mounted() {
        this.wsDonationUpdate();
    },
    methods: {
        ...mapActions(['donateSearch']),
        
        getDonateLists() {
            this.donateSearch({ limit: 10 }).then(async (response) => {
                const res = response.data.data;
                if(res) this.donateLists = res;
            }).catch();
        },
        wsDonationUpdate() {
            Echo.channel('donation').listen('.created',(donationItem) => {
                this.donateLists.pop();
                this.donateLists.unshift(donationItem);
            });
            Echo.channel('donation').listen('.updated',(donationItem) => {
                const updatedIndex = this.donateLists.findIndex(item => item.id === donationItem.id);
                if (updatedIndex !== -1) {
                    this.donateLists.splice(updatedIndex, 1, donationItem); 
                } else {
                    this.donateLists.unshift(donationItem);
                    this.donateLists.pop();
                }
            });
        }
    },
    beforeDestroy() {
        Echo.leaveChannel('donation');
    }
}
</script>
